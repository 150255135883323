import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics"; 

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "businesscard-update.firebaseapp.com",
  databaseURL: "https://businesscard-update-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "businesscard-update",
  storageBucket: "businesscard-update.appspot.com",
  messagingSenderId: "917392171117",
  appId: "1:917392171117:web:8ab5a12d84b6afbe7e5f64",
  measurementId: "G-116NZZ9XQ5"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
