// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import "./Swiper.scss";
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

// Import Swiper styles
import 'swiper/css';




const SwiperComponent = () => {
  return (
    <div className='swiper-container'>
    <h5 className='swiper-small-header'>Digilinko Dijital Kartlarınız ile</h5>
    <h1 className='swiper-header'>Tüm bilgilerinizi temassız bir şekilde karşı tarafa aktarabilirsiniz.</h1>
    <Swiper
    breakpoints={
      {
    // when window width is >= 640px
    240: {
      slidesPerView: 1,
    },
    900: {
      slidesPerView: 3,
    },
    }
    }
      spaceBetween={30}
    >

      <SwiperSlide className='swiper-slide'>
        <div className='swipe-container'>
            <div className='icon'>
              <ContactPhoneOutlinedIcon/>
            </div>

            <h4 className='header'>Telefona Kaydet</h4>
            <div className='content'>
              <p>Profilinize eklediğiniz bir buton sayesinde, ziyaretçileriniz profilinizi görüntülerken, profilinize entegre edilmiş rehber butonuna tıklayarak, sizi doğrudan rehberlerine ekleyebilirler.
             </p>
            </div>
        </div>
       </SwiperSlide>
      <SwiperSlide className='swiper-slide'>
        <div className='swipe-container'>
              <div className='icon'>
                <InstagramIcon/>
              </div>
            <h4 className='header'>Sosyal Medya</h4>

              <div className='content'>
                <p>Digilinko profilinizde sosyal medya hesaplarınızı ekleyerek, profilinizi ziyaret eden kişilerin kolayca sosyal medya hesaplarınızı bulmalarını sağlayabilirsiniz.</p>
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide className='swiper-slide'>
        <div className='swipe-container'>
              <div className='icon'>
                <LanguageIcon/>
              </div>
            <h4 className='header'>Websitesi</h4>

              <div className='content'>
                <p>Digilinko profilinizde işletmenizin veya kişisel websitesini ekleyerek, profilinizi ziyaret eden kişilerin web sitenize kolayca ulaşmalarını sağlayabilirsiniz.</p>
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide className='swiper-slide'>
        <div className='swipe-container'>
              <div className='icon'>
                <LocationOnIcon/>
              </div>
            <h4 className='header'>Adres</h4>

              <div className='content'>
                <p>Digilinko profilinizde işletmenizin veya kişisel adres bilgilerinizi ekleyebilirsiniz. Bu sayede müşterileriniz veya arkadaşlarınız, size kolayca ulaşabilirler.</p>
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide className='swiper-slide'>
        <div className='swipe-container'>
              <div className='icon'>
                <EmailOutlinedIcon/>
              </div>
            <h4 className='header'>Mail</h4>

              <div className='content'>
                <p>Digilinko profilinizde işletmenizin veya kişisel e-posta adresini ekleyerek, profilinizi ziyaret eden kişilerin size kolayca e-posta göndermelerini sağlayabilirsiniz.</p>
              </div>
          </div>
      </SwiperSlide>
    </Swiper>
    </div>
  );
};
export default SwiperComponent;
