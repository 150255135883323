import Login from "./pages/login/Login";
import Profile from "./pages/profile/Profile";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext, useEffect } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";
import UpdateUser from "./pages/updateuser/updateUser";
import UpdatePassword from "./pages/updatepassword/updatepassword";
import Home from "./pages/home/Home";
import Admin from "./pages/admin/admin";
import Users from "./pages/users/users";
import ProfileAnalytics from "./pages/profileanalytics/profileAnalytics";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  const { currentUser } = useContext(AuthContext);

  const isUserIdValid = () => {
    return true;
    //return window.location.pathname.length === 29;
  };

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  const RequireAdmin = ({ children }) => {
    const adminUID = "KOWSyx7jv4drzXagseIQ4QRBEE12"; // Admin UID'si
    // Eğer mevcut kullanıcı admin UID'sine sahipse, çocuk bileşenleri render et
    if (currentUser && currentUser.uid === adminUID) {
      return children; // Admin sayfasını göster
    } else {
      // Eğer kullanıcı giriş yapmışsa ama admin değilse, UpdateUser sayfasına yönlendir
      return <Navigate to="/update-user" />;
    }
  };



  
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route  exact  path="/"  element={<Login />}>

          </Route>
          <Route
              path="/:identifier"
              element={
                isUserIdValid() ? <Profile /> : <Navigate to="/login" replace />
              }
            />
            <Route path="/login" element={<Login />} />
            {/* <Route path="/home" element={<HomeNavbar />} /> */}

             <Route
              path="/home"
              element={
                  <Home  />
              }
            /> 
            <Route
              path="/updateuser"
              element={
                <RequireAuth>
                  <UpdateUser inputs={userInputs} title="Bilgileri Güncelle" />
                </RequireAuth>
              }
            />
            <Route
              path="/updatepassword"
              element={
                <RequireAuth>
                  <UpdatePassword />
                </RequireAuth>
              }
            />
            <Route
              path="/profileanalytics"
              element={
                <RequireAuth>
                  <ProfileAnalytics />
                </RequireAuth>
              }
            />
            <Route
                  path="/admin"
                  element={
                <RequireAuth>

                    <RequireAdmin>
                      <Admin />
                    </RequireAdmin>
                </RequireAuth>

                  }
                />
                            <Route
                  path="/users"
                  element={
                <RequireAuth>

                    <RequireAdmin>
                      <Users />
                    </RequireAdmin>
                </RequireAuth>

                  }
                />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
