import React, { useEffect, useState } from 'react';

const Users = () => {
  const [users, setUsers] = useState([]);

  // Kullanıcıları yüklemek için fonksiyon
  const fetchUsers = async () => {
    try {
      const response = await fetch('https://us-central1-businesscard-update.cloudfunctions.net/listUsers-2'); // Cloud Function endpoint'iniz
      if (!response.ok) {
        throw new Error('Kullanıcılar yüklenemedi');
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Kullanıcıları yüklerken hata oluştu:', error);
    }
  };

  // Kullanıcıyı silmek için fonksiyon
  const deleteUser = async (uid) => {
    try {
      const response = await fetch(`/user/${uid}`, { method: 'DELETE' });
      if (!response.ok) {
        throw new Error('Kullanıcı silinemedi');
      }
      fetchUsers(); // Kullanıcı listesini yenile
    } catch (error) {
      console.error('Kullanıcıyı silerken hata oluştu:', error);
    }
  };

  // Kullanıcıyı dondurmak için fonksiyon
  const disableUser = async (uid) => {
    try {
      const response = await fetch(`/user/${uid}/disable`, { method: 'POST' });
      if (!response.ok) {
        throw new Error('Kullanıcı dondurulamadı');
      }
      fetchUsers(); // Kullanıcı listesini yenile
    } catch (error) {
      console.error('Kullanıcıyı dondururken hata oluştu:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
      <h1>Kullanıcılar</h1>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Oluşturma Tarihi</th>
            <th>UID</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.uid}>
              <td>{user.email}</td>
              <td>{user.creationTime}</td>
              <td>{user.uid}</td>
              <td>
                <button onClick={() => disableUser(user.uid)}>Dondur</button>
                <button onClick={() => deleteUser(user.uid)}>Sil</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
