import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from '../locales/en/translation.json';
import translationDE from '../locales/de/translation.json';
import translationTR from '../locales/tr/translation.json';
import translationRU from '../locales/ru/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  tr: {
    translation: translationTR
  },
  ru: {
    translation: translationRU
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;