import "./updateUser.scss";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { AuthContext } from "../../context/AuthContext";
import {  Navigate } from "react-router-dom";
import { debounce } from 'lodash';
import { useEffect, useContext ,useState } from "react";
import {
  collection,
  doc,
  serverTimestamp,
  updateDoc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { useNavigate } from "react-router-dom";
import QRCode from 'qrcode.react';
import { Helmet } from 'react-helmet';
import { useTranslation, I18nextProvider } from 'react-i18next';



const UpdateUser = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [logo, setLogoFile] = useState("");
  const [data, setData] = useState({});
  const [qrValue, setQrValue] = useState('');

  const [userdata, setUserData] = useState({});
  const [per, setPerc] = useState(null);
  const navigate = useNavigate();
  const user = auth.currentUser;
  var storageRef = "";
  const form = document.querySelector("form");
  const nameInput = document.getElementById("name");
  const surnameInput = document.getElementById("surname");
  const phoneInput = document.getElementById("phone");
  const emailInput = document.getElementById("email");
  const websiteInput = document.getElementById("website");
  const companyInput = document.getElementById("company");
  const jobInput = document.getElementById("job");
  const addressInput = document.getElementById("address");
  const noteInput = document.getElementById("note");
  const instagramInput = document.getElementById("instagram");
  const linkedinInput = document.getElementById("linkedin");
  const facebookInput = document.getElementById("facebook");
  const twitterInput = document.getElementById("twitter");
  const tiktokInput = document.getElementById("tiktok");
  const { t, i18n  } = useTranslation();
  const browserLanguage = navigator.language.substring(0, 2);
  const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // Varsayılan beyaz
  const [iconBoxColor, setIconBoxColor] = useState('#ffffff'); // Varsayılan beyaz
  const [iconColor, setIconColor] = useState('#ffffff'); // Varsayılan beyaz
  const [textColor, setTextColor] = useState('#ffffff'); // Varsayılan beyaz
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");

function createVcfqr(data){
  let vcard = "BEGIN:VCARD\n";
  vcard += "VERSION:3.0\n";
  vcard += `FN:${data.name}\n`;
  vcard += `TEL;TYPE=CELL:${data.phone}\n`;
  if (data.surname !== ""&& data.surname !== null) {
    vcard += `N:${data.surname};${data.name}\n`;
  }
  if (data.email !== ""&& data.email !== null) {
    vcard += `EMAIL:${data.email}\n`;
  }
  if (data.website !== ""&& data.website !== null) {
    vcard += `URL:${data.website}\n`;
  }
  if (data.company !== ""&& data.company !== null) {
    vcard += `ORG:${data.company}  `;
  }
  if (data.job !== "" && data.job !== null) {
    vcard += `${data.job}\n`;
  }
  if (data.address !== "" && data.address !== null) {
    vcard += `ADR:${data.address}\n`;
  }
  if (data.note !== "" && data.note !== null) {
    vcard += `NOTE:${data.note}\n`;
  }
  if (data.instagram !== "" && data.instagram !== null) {
    vcard += `X-SOCIALPROFILE;TYPE=instagram:${data.instagram}\n`;
  }
  if (data.linkedin !== "" && data.linkedin !== null) {
    vcard += `X-SOCIALPROFILE;TYPE=linkedin:${data.linkedin}\n`;
  }
  if (data.facebook !== "" && data.facebook !== null) {
    vcard += `X-SOCIALPROFILE;TYPE=facebook:${data.facebook}\n`;
  }
  if (data.twitter !== "" && data.twitter !== null) {
    vcard += `X-SOCIALPROFILE;TYPE=twitter:${data.twitter}\n`;
  }
  if (data.tiktok !== "" && data.tiktok !== null) {
    vcard += `X-SOCIALPROFILE;TYPE=tiktok:${data.tiktok}\n`;
  }
  vcard += "END:VCARD";

  setQrValue(vcard);
  
}
const [activeTab, setActiveTab] = useState('personalInfo');

const handleTabChange = async (tab) => {
  setActiveTab(tab);
  await SelectData();
};
const { currentUser } = useContext(AuthContext);







  useEffect(() => {
    // 👇️ only runs once

    i18n.changeLanguage(browserLanguage);
    const select = document.getElementById("language-select");
    select.value = browserLanguage;




    async function SelectData() {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {


        if(doc.data().uid === auth.currentUser.uid){
            var keys= Object.keys(doc.data());
            keys.forEach((key)=>{
              
              const forminput = document.getElementById(String(key));
              if(forminput!==null && key!== "logo"){

                forminput.value = doc.data()[String(key)];
                setData(prevUserData => ({
                  ...prevUserData,
                  [key]: forminput.value
                }));
                if(key==="username"){
                  setCurrentUsername(doc.data()[String(key)]);
                  setIsButtonActive(true);

                }
              }

            })
        }

        //console.log(doc.id, " => ", doc.data(), Object.keys(doc.data()));
      });
    }
    SelectData();
  }, []);
  useEffect(() => {
    const fetchUserData = async () => {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        setBackgroundColor(userDoc.data().backgroundColor || '#ffffff');
        setIconColor(userDoc.data().textColor || '#ffffff');
        setTextColor(userDoc.data().textColor || '#ffffff');
        setIconBoxColor(userDoc.data().iconBoxColor || '#ffffff');
      }
      setIsLoading(false);
    };
    fetchUserData();
    SelectData();
  }, [currentUser.uid]);
  async function SelectData() {
    const q = query(collection(db, "users"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {


      if(doc.data().uid === auth.currentUser.uid){
          var keys= Object.keys(doc.data());
          keys.forEach((key)=>{
            
            const forminput = document.getElementById(String(key));
            if(forminput!==null && key!== "logo"){

              forminput.value = doc.data()[String(key)];
              setData(prevUserData => ({
                ...prevUserData,
                [key]: forminput.value
              }));

            }

          })
      }

      //console.log(doc.id, " => ", doc.data(), Object.keys(doc.data()));
    });
  }
  // VCard oluşturma işlevi
  async function generateVCard() {
    console.log("generateVCard fonksiyonu çalıştı");

    // Verileri alın
    const name = data.name;
    const surname = data.surname;
    const phone = data.phone;
    const email = data.email;
    const website = data.website;
    const company = data.company;
    const job = data.job;
    const address = data.address;
    const note = data.note;
    const instagram = data.instagram;
    const linkedin = data.linkedin;
    const facebook = data.facebook;
    const twitter = data.twitter;
    const tiktok = data.tiktok;

    // VCard dosyasını oluşturun
    let vcard = "BEGIN:VCARD\n";
    vcard += "VERSION:3.0\n";
    vcard += `FN:${name}\n`;
    vcard += `TEL;TYPE=CELL:${phone}\n`;
    if (surname !== ""&& surname !== null) {
      vcard += `N:${surname};${name}\n`;
    }
    if (email !== ""&& email !== null) {
      vcard += `EMAIL:${email}\n`;
    }
    if (website !== ""&& website !== null) {
      vcard += `URL:${website}\n`;
    }
    if (company !== ""&& company !== null) {
      vcard += `ORG:${company}  `;
    }
    if (job !== "" && job !== null) {
      vcard += `${job}\n`;
    }
    if (address !== "" && address !== null) {
      vcard += `ADR:${address}\n`;
    }
    if (note !== "" && note !== null) {
      vcard += `NOTE:${note}\n`;
    }
    if (instagram !== "" && instagram !== null) {
      vcard += `X-SOCIALPROFILE;TYPE=instagram:${instagram}\n`;
    }
    if (linkedin !== "" && linkedin !== null) {
      vcard += `X-SOCIALPROFILE;TYPE=linkedin:${linkedin}\n`;
    }
    if (facebook !== "" && facebook !== null) {
      vcard += `X-SOCIALPROFILE;TYPE=facebook:${facebook}\n`;
    }
    if (twitter !== "" && twitter !== null) {
      vcard += `X-SOCIALPROFILE;TYPE=twitter:${twitter}\n`;
    }
    if (tiktok !== "" && tiktok !== null) {
      vcard += `X-SOCIALPROFILE;TYPE=tiktok:${tiktok}\n`;
    }
    vcard += "END:VCARD";

console.log("oluştur");

    // Dosya adı ve içeriği oluşturun
    const filename = `${name}${surname}.vcf`;
    const file = new Blob([vcard], { type: "text/vcard" });
    storageRef = ref(storage, `/${auth.currentUser.uid}/${filename}`);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      await updatevcfLink(url);
      console.log("vcf link updated");
    } catch (error) {
      console.error("Hata oluştu: ", error);
    }
    /*uploadBytes(storageRef, file).then((snapshot) => {
      getDownloadURL(storageRef).then(url => {
        updatevcfLink(url);
        console.log("vcf link updated");
      });
    });*/
console.log("bitti");


  }

  async function updatevcfLink(link){
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      vcfLink : link,
      userLink: "https://digilinko.com/" +auth.currentUser.uid,
    });
  }
  //console.log(userdata.name);
  //console.log(docSnap);
  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      console.log(name);
      const storageRef = ref(storage, `${auth.currentUser.uid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);
  useEffect(() => {
    const uploadLogoFile = () => {
      const name = new Date().getTime() + logo.name;

      const storageRef = ref(storage, `${auth.currentUser.uid}/${logo.name}`);
      const uploadTask = uploadBytesResumable(storageRef, logo);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, logo: downloadURL }));
          });
        }
      );
    };
    logo && uploadLogoFile();
  }, [logo]);
  // console.log(data);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    if(id==="phone"){

      setData({...data, [id]: value, whatsapp: value });

    }
    if(id==="username"){
      setUsername(value);

      if (value.length > 0) {
        const isAvailable = checkUsernameAvailability(value);
        setUsernameAvailable(isAvailable);
        setData({...data, username: value });
      } else {
        setUsernameAvailable(null);
      }
    }
    else{
      setData({ ...data, [id]: value });

    }
  };
  const [username, setUsername] = useState('');
  const [currentUsername, setCurrentUsername] = useState('');
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  useEffect(() => {
    setIsButtonActive(usernameAvailable);
  }, [usernameAvailable]);
  const checkUsernameAvailability = async (username) => {
    try {
      const q = query(collection(db, "users"), where("username", "==", username ));
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty;
    } catch (error) {
      console.error("Kullanıcı adı kontrolü sırasında hata oluştu: ", error);
      return false;
    }
  };

  useEffect(() => {
    if (username.length > 0) {
      const delayedCheck = debounce(async () => {
        if(currentUsername===username){
          setUsernameAvailable(true);
        }
        else{
          const isAvailable = await checkUsernameAvailability(username);
          setUsernameAvailable(isAvailable);
        }
      }, 500);

      delayedCheck();
      return delayedCheck.cancel;
    } else {
      setUsernameAvailable(null);
    }
  }, [username]);

  const handleAdd = async (e) => {
    e.preventDefault();
    if(isButtonActive){
      try {
        // const res = await createUserWithEmailAndPassword(
        //   auth,
        //   data.email,
        //   data.password
        // );
          await generateVCard();
        await updateDoc(doc(db, "users", auth.currentUser.uid), {
          ...data,
          timeStamp: serverTimestamp(),
          uid : auth.currentUser.uid,
        });
  
        localStorage.setItem('uid', auth.currentUser.uid);
  
         navigate("/updateuser", { replace: true }); 
         window.location.reload(); 
        console.log("değişti.");
      } catch (err) {
        console.log(err);
      }}
      else{
        
      }
    

  };
  const handleBackgroundColorChange = async (e) => {
    const newColor = e.target.value;
    setBackgroundColor(newColor);

    // Firestore'da güncelle
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, {
      backgroundColor: newColor,
    });
  };
  const handleTextColorChange = async (e) => {
    const newColor = e.target.value;
    setTextColor(newColor);

    // Firestore'da güncelle
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, {
      textColor: newColor,
    });
  };
  const handleIconColorChange = async (e) => {
    const newColor = e.target.value;
    setIconColor(newColor);

    // Firestore'da güncelle
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, {
      iconColor: newColor,
    });
  };
  const handleIconBoxColorChange = async (e) => {
    const newColor = e.target.value;
    setIconBoxColor(newColor);

    // Firestore'da güncelle
    const userRef = doc(db, 'users', auth.currentUser.uid);
    await updateDoc(userRef, {
      iconBoxColor: newColor,
    });

  };


  return (
    <div className="updateUser">
      <div className="updateUserContainer">
        <Navbar />
        <div className="tabHeaders">
        <button onClick={() => handleTabChange('personalInfo')}>Kişisel Bilgiler</button>
        <button onClick={() => handleTabChange('socialMedia')}>Sosyal Medya Hesapları</button>
        <button onClick={() => handleTabChange('companyInformations')}>Şirket Bilgileri</button>
        <button onClick={() => handleTabChange('bankInformations')}>Banka Bilgileri</button>
        <button onClick={() => handleTabChange('markeplaces')}>{t('update.marketplace')}</button>
        <button onClick={() => handleTabChange('images')}>Görseller</button>
        <button onClick={() => handleTabChange('template')}>Template</button>
        {/* Diğer sekmeler için butonlar... */}
      </div>
        <form onSubmit={handleAdd}>
{/* Sekme İçerikleri */}


{activeTab === 'personalInfo' && (
        <div className="tabContainer">
          <div className="formRow">
                      <div className="formInput" key={"name"}>
                  <label>{t('update.name')}</label>
                  <input
                    id={"name"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
              
              
              <div className="formInput" key={"username"}>
                <label>{t('update.username')}</label>
                <div className="inputContainer inputUsernameContainer">
                  <input
                    id={"username"}
                    type={"text"}
                    onChange={handleInput}
                    className={usernameAvailable !== null ? (usernameAvailable ? 'valid' : 'invalid') : ''}
                  />
                  {usernameAvailable === true && <span className="inputUsernameIcon">✅</span>}
                  {usernameAvailable === false && <span className="inputUsernameIcon">❌</span>}
                </div>
              </div>
              </div>

              <div className="formRow">

              <div className="formInput" key={"surname"}>
                  <label>{t('update.surname')}</label>
                  <input
                    id={"surname"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>

              <div className="formInput" key={"phone"}>
                  <label>{t('update.tel')}</label>
                  <input
                    id={"phone"}
                    type={"tel"}
                    onChange={handleInput}
                  />
              </div>
              </div>

              <div className="formRow">

              <div className="formInput" key={"email"}>
                  <label>{t('update.email')}</label>
                  <input
                    id={"email"}
                    type={"email"}
                    onChange={handleInput}
                  />
              </div>

              <div className="formInput" key={"website"}>
                  <label>{t('update.website')}</label>
                  <input
                    id={"website"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
              </div>
              <div className="formRow">

              <div className="formInput" key={"company"}>
                  <label>{t('update.company')}</label>
                  <input
                    id={"company"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>

              <div className="formInput" key={"job"}>
                  <label>{t('update.job')}</label>
                  <input
                    id={"job"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
              </div>
              <div className="formRow">

              <div className="formInput" key={"address"}>
                  <label>{t('update.address')}</label>
                  <input
                    id={"address"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>


              <div className="formInput" key={"note"}>
                  <label>{t('update.not')}</label>
                  <input
                    id={"note"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
              </div>

        </div>
      )}
      {activeTab === 'socialMedia' && (
        <div>
          <div className="tabContainer">
            <div className="formRow">
              <div className="formInput" key={"instagram"}>
                  <label>{t('update.instagram')}</label>
                  <input
                    id={"instagram"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>

              <div className="formInput" key={"linkedin"}>
                  <label>{t('update.linkedin')}</label>
                  <input
                    id={"linkedin"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
            </div>

            <div className="formRow">

              <div className="formInput" key={"facebook"}>
                  <label>{t('update.facebook')}</label>
                  <input
                    id={"facebook"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>

              <div className="formInput" key={"youtube"}>
                  <label>{t('update.youtube')}</label>
                  <input
                    id={"youtube"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
            </div>

            <div className="formRow">

              <div className="formInput" key={"twitter"}>
                  <label>{t('update.twitter')}</label>
                  <input
                    id={"twitter"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>

              <div className="formInput" key={"tiktok"}>
                  <label>{t('update.tiktok')}</label>
                  <input
                    id={"tiktok"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
            </div>
            </div>

        </div>
      )}

      {activeTab === 'companyInformations' && (
        <div>
          {/* İletişim Bilgileri Sekmesi İçeriği */}
        </div>
      )}
      {activeTab === 'markeplaces' && (
        <div>
          <div className="tabContainer">

          <div className="formRow">

              <div className="formInput" key={"trendyol"}>
                  <label>{t('update.trendyol')}</label>
                  <input
                    id={"trendyol"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>

              <div className="formInput" key={"hepsiburada"}>
                  <label>{t('update.hepsiburada')}</label>
                  <input
                    id={"hepsiburada"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
            </div>

            <div className="formRow">

              <div className="formInput" key={"sahibinden"}>
                  <label>{t('update.sahibinden')}</label>
                  <input
                    id={"sahibinden"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>

              <div className="formInput" key={"amazon"}>
                  <label>{t('update.amazon')}</label>
                  <input
                    id={"amazon"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
              </div>
              </div>
        </div>
      )}

      {activeTab === 'companyInformations' && (
              <div>
                <div className="tabContainer">

                    <div className="formRow">
                    <div className="formInput" key={"officialName"}>
                        <label>{t('update.officialName')}</label>
                        <input
                          id={"officialName"}
                          type={"text"}
                          onChange={handleInput}
                        />
                    </div>
                    <div className="formInput" key={"officalAddress"}>
                        <label>{t('update.officalAddress')}</label>
                        <input
                          id={"officalAddress"}
                          type={"text"}
                          onChange={handleInput}
                        />
                    </div>

                      </div>

                      <div className="formRow">

                      <div className="formInput" key={"officialVAT"}>
                        <label>{t('update.officialVAT')}</label>
                        <input
                          id={"officialVAT"}
                          type={"text"}
                          onChange={handleInput}
                        />
                    </div>

                      </div>

                      <div className="formRow">
                    <div className="formInput" key={"officialName2"}>
                        <label>{t('update.officialName2')}</label>
                        <input
                          id={"officialName2"}
                          type={"text"}
                          onChange={handleInput}
                        />
                    </div>
                    <div className="formInput" key={"officalAddress2"}>
                        <label>{t('update.officalAddress2')}</label>
                        <input
                          id={"officalAddress2"}
                          type={"text"}
                          onChange={handleInput}
                        />
                    </div>

                      </div>

                      <div className="formRow">


                    
                      <div className="formInput" key={"officialVAT2"}>
                        <label>{t('update.officialVAT2')}</label>
                        <input
                          id={"officialVAT2"}
                          type={"text"}
                          onChange={handleInput}
                        />
                    </div>
                      </div>
                  </div>
              </div>
            )}
      {activeTab === 'bankInformations' && (
        <div>
          <div className="tabContainer">

              <div className="formRow">
              <div className="formInput" key={"bankName"}>
                  <label>{t('update.bankName')}</label>
                  <select  id="bankName" onChange={handleInput}>
                    <option >Akbank</option>
                    <option value="Denizbank A.Ş.">Denizbank A.Ş.</option>
                    <option value="Deutsche Bank">Deutsche Bank</option>
                    <option value="Ekonomi Bankası">Ekonomi Bankası</option>
                    <option value="Enpara">Enpara</option>
                    <option value="Garanti Bankası">Garanti Bankası</option>
                    <option value="Halk Bankası">Halk Bankası</option>
                    <option value="HSBC Bank A.Ş.">HSBC Bank A.Ş.</option>
                    <option value="İNG Bank">İNG Bank</option>
                    <option value="İller Bankası">İller Bankası</option>
                    <option value="İş Bankası">İş Bankası</option>
                    <option value="Kuveyt Türk Katılım Bankası">Kuveyt Türk Katılım Bankası</option>
                    <option value="Paraf">Paraf</option>
                    <option value="QNB Finansbank">QNB Finansbank</option>
                    <option value="T.C. Merkez Bankası">T.C. Merkez Bankası</option>
                    <option value="T.C. Ziraat Bankası">T.C. Ziraat Bankası</option>
                    <option value="Türkiye Emlak Katılım Bankası">Türkiye Emlak Katılım Bankası</option>
                    <option value="Türkiye Finans Katılım Bankası">Türkiye Finans Katılım Bankası</option>
                    <option value="Vakıf Katılım Bankası">Vakıf Katılım Bankası</option>
                    <option value="Yapı ve Kredi Bankası">Yapı ve Kredi Bankası</option>
                    <option value="Ziraat Katılım Bankası">Ziraat Katılım Bankası</option>
                    <option value="Şekerbank">Şekerbank</option>
                 </select>
              </div>



                </div>

                <div className="formRow">

                <div className="formInput" key={"bankIban"}>
                  <label>{t('update.bankIban')}</label>
                  <input
                    id={"bankIban"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>
              <div className="formInput" key={"bankRecipient"}>
                  <label>{t('update.bankRecipient')}</label>
                  <input
                    id={"bankRecipient"}
                    type={"text"}
                    onChange={handleInput}
                  />
              </div>  
                </div>
            </div>
        </div>
      )}

      {activeTab === 'images' && (
              <div>
                <div className="tabContainer">



                      <div className="formRow">
                      <div className="upload-photo">

                    <div className="formInput imgformInput">
                    <img
                                  src={
                                    file
                                      ? URL.createObjectURL(file)
                                      : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                                  }
                                  alt=""
                                />

                                
                      <label className="imgformLabel" htmlFor="file">
                      {t('update.uploadprofilepic')} <DriveFolderUploadOutlinedIcon className="icon" />
                      </label>
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        style={{ display: "none" }}
                      />
                      <a href="#" onClick={(e) => { e.preventDefault(); setData({...data, img: "" }); setInfoMessage("Güncelle Butonuna tıklanınca silme işlemi tamamlanacaktır."); }}>
                      {t('update.deleteIMG')} ❌
                      </a>

                    </div>
                    <div className="formInput imgformInput">
                        <img
                                        src={
                                          logo
                                            ? URL.createObjectURL(logo)
                                            : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                                        }
                                        alt="logo"
                                      />
                          <label className="imgformLabel" htmlFor="logo">
                          {t('update.uploadlogo')} <DriveFolderUploadOutlinedIcon className="icon" />
                          </label>
                          <input
                            type="file"
                            id="logo"
                            onChange={(e) => setLogoFile(e.target.files[0])}
                            style={{ display: "none" }}
                          />
                          <a href="#" onClick={(e) => { e.preventDefault(); setData({...data, logo: "" });setInfoMessage("Güncelle Butonuna tıklanınca silme işlemi tamamlanacaktır."); }}>
                          {t('update.deleteIMG')} ❌
                          </a>
                        </div>
                        </div>
                      </div>
                  </div>
              </div>
            )}

      {activeTab === 'template' && (
              <div>
                <div className="tabContainer">

                    <div className="formRow">

                    <div className="formInput" key={"template"}>
                  <label>{t('update.templateLabel')}</label>
                  <select  id="template" onChange={handleInput}>
                    <option value="box" >{t('update.templateNormal')}</option>
                    <option value="template-box-container">{t('update.templateBox')}</option>
                 </select>
              </div>


                      </div>

                      <div className="formRow">
                      <div className="formInput colorInput">
                  <label htmlFor="backgroundColor">{t('update.backgroundColor')}</label>
                  <input
                    type="color"
                    id="backgroundColor"
                    value={backgroundColor}
                    onChange={handleBackgroundColorChange}
                    className="color-picker-input"
                  />
              </div>
              <div className="formInput colorInput">
                  <label htmlFor="textColor">{t('update.textColor')}</label>
                  <input
                    type="color"
                    id="textColor"
                    value={textColor}
                    onChange={handleTextColorChange}
                    className="color-picker-input"

                  />
              </div>
                      </div>
                      <div className="formRow">
                      <div className="formInput colorInput">
                  <label htmlFor="iconColor">{t('update.iconColor')}</label>
                  <input
                    type="color"
                    id="iconColor"
                    value={iconColor}
                    onChange={handleIconColorChange}
                    className="color-picker-input"

                  />
              </div>
              <div className="formInput colorInput">
                  <label htmlFor="iconBoxColor">{t('update.iconBoxColor')}</label>
                  <input
                    type="color"
                    id="iconBoxColor"
                    value={iconBoxColor}
                    onChange={handleIconBoxColorChange}
                    className="color-picker-input"

                  />
              </div>
                      </div>
                  </div>
              </div>
            )}
            <div>

              {infoMessage && <div className="info-message">{t('update.deleteInformation')}</div>}
            </div>
              <div className="form-button-container">

                  <button disabled={(per !== null && per < 100) || !isButtonActive }  type="submit">
                  {t('update.updatebutton')}
                  </button>
              </div>
            </form>

      </div>
    </div>
  );
};

export default UpdateUser;
