import "./updatepassword.scss";
import { useState } from "react";
import { auth } from "../../firebase";
import { updatePassword  } from "firebase/auth";
import Navbar from "../../components/navbar/Navbar";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

function PasswordChangeForm() {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const { t, i18n  } = useTranslation();
  const browserLanguage = navigator.language.substring(0, 2);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    // 👇️ only runs once

    i18n.changeLanguage(browserLanguage);
    const select = document.getElementById("language-select");
    select.value = browserLanguage;
    console.log(select.value);



  }, []);

  const handlePasswordChange = async (event) => {
    event.preventDefault();

    if (password !== passwordConfirmation) {
      setError("Şifreler eşleşmiyor.");
      return;
    }

    try {
      await updatePassword(auth.currentUser, password);
      setIsSuccess(true);
      setPassword("");
      setPasswordConfirmation("");
      setError("");
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePasswordInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "password") {
      setPassword(value);
    } else {
      setPasswordConfirmation(value);
    }
  };

  return (
    <div>
        <Navbar />

    <div className="updatepassword">
        <div className="cardStyle">

            <form onSubmit={handlePasswordChange}>
            <h2 className="formTitle">
            {t('updatePassword.updatePassword')} 
             </h2>
            <div className="inputDiv">
                <label className="inputLabel" htmlFor="password">{t('updatePassword.newPassword')}   </label>
                <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordInputChange}
                />
            </div>
            <div className="inputDiv">
                <label className="inputLabel" htmlFor="password-confirmation">{t('updatePassword.newPasswordAgain')}   </label>
                <input
                type="password"
                id="password-confirmation"
                name="password-confirmation"
                value={passwordConfirmation}
                onChange={handlePasswordInputChange}
                />
            </div>
            <div className="buttonWrapper">
                <button className="submitButton pure-button pure-button-primary" type="submit"><span>{t('updatePassword.updateButton')}</span></button>
            </div>
            {isSuccess && <p className="updatedinformation">{t('updatePassword.updatedinformation')}</p>}

            </form>
        </div>
    </div>

    </div>
  );
}

export default PasswordChangeForm;
