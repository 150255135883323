export const userInputs = [
    {
      id: "name",
      label: "Ad",
      type: "text",
      placeholder: "",
    },
    {
      id: "surname",
      label: "Soyad",
      type: "text",
      placeholder: "",
    },
    // {
    //   id: "password",
    //   label: "Şifre",
    //   type: "password",
    //   placeholder: "",
    // },
    {
      id: "phone",
      label: "Tel",
      type: "tel",
      placeholder: "",
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      placeholder: "",
    },
    {
      id: "website",
      label: "Website",
      type: "text",
    },
    {
      id: "company",
      label: "Şirket",
      type: "text",
      placeholder: "",
    },
    {
      id: "job",
      label: "Meslek",
      type: "text",
      placeholder: "",
    },
    {
      id: "address",
      label: "Adres",
      type: "text",
      placeholder: "",
    },
    {
      id: "note",
      label: "Not",
      type: "text",
      placeholder: "",
    },
    {
      id: "instagram",
      label: "Instagram",
      type: "text",
      placeholder: "",
    },
    {
      id: "linkedin",
      label: "Linkedin",
      type: "text",
      placeholder: "",
    },
    {
      id: "facebook",
      label: "Facebook",
      type: "text",
      placeholder: "",
    },
    {
      id: "twitter",
      label: "Twitter",
      type: "text",
      placeholder: "",
    },
    {
      id: "tiktok",
      label: "Tiktok",
      type: "text",
      placeholder: "",
    },
    
  ];
  
  export const productInputs = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];
  