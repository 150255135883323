import Intro from "../../components/home/Intro/Intro";
import Navbar from "../../components/home/Navbar/HomeNavbar";
import Midcontainer  from "../../components/home/Midcontainer/Midcontainer";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import SwiperComponent from "../../components/home/Swiper/Swiper";
import { useEffect } from "react";
import Profile from "../profile/Profile";



const Home = () => {

  
// useEffect(() => {

//   if(window.location.pathname.length === 29){
//     <Profile/>
//   }
//   else{
//    window.location.href = "https://shop.digilinko.com";
//   }
//  }, []);

  return (
    <div className="home">
        
         <Navbar />
        <Intro/>
        <SwiperComponent  />
        <Midcontainer />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default Home;
