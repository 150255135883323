import { useContext, useEffect, useState } from "react";
import "./login.scss";
import { signInWithEmailAndPassword,sendPasswordResetEmail  } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import {AuthContext} from "../../context/AuthContext"
import { BsWhatsapp } from 'react-icons/bs';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t, i18n  } = useTranslation();
  const browserLanguage = navigator.language.substring(0, 2);
  const navitage = useNavigate();

  const {dispatch} = useContext(AuthContext);
  useEffect(() => {
    // 👇️ only runs once

    i18n.changeLanguage(browserLanguage);
    const select = document.getElementById("language-select");
    select.value = browserLanguage;
    console.log(select.value);



  }, []);
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
  };
  const handleLogin = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch({type:"LOGIN", payload:user})
        localStorage.setItem('uid', auth.currentUser.uid);
        navitage("/updateuser")
      })
      .catch((error) => {
        setError(true);
      });
  };
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState("");

  const openResetModal = () => setIsResetModalOpen(true);
  const closeResetModal = () => setIsResetModalOpen(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      alert("Şifre sıfırlama e-postası gönderildi!");
      closeResetModal();
    } catch (error) {
      console.error("Şifre sıfırlama hatası:", error);
      alert(error.message);
    }
  };

  return (
    <div className="fullpage">

      <div className="login">
      <div className="language-select-div">
        <select id="language-select" onChange={handleLanguageChange}>
          <option value="en">EN</option>
          <option value="de">DE</option>
          <option value="tr">TR</option>
          <option value="ru">RU </option>
        </select>
      </div>
        <form onSubmit={handleLogin}>
        <img className="Login-Logo" src="/images/Digilinko-logo-black-dikey.png" />
          <input
            type="email"
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">{t('login.login')}</button>
          {error && <span>{t('login.error')}</span>}


        </form>
        <button className="forgetButton" onClick={openResetModal}>Şifremi Unuttum</button>

{isResetModalOpen && (
  <div className="modal">
    <div className="modal-content">
      <span className="close" onClick={closeResetModal}>&times;</span>
      <form onSubmit={handlePasswordReset}>
        <h2>Şifre Sıfırlama</h2>
        <input
          type="email"
          value={resetEmail}
          onChange={(e) => setResetEmail(e.target.value)}
          placeholder="E-posta adresinizi girin"
          required
        />
        <button type="submit">Şifre Sıfırla</button>
      </form>
    </div>
  </div>
)}
        
      <div className="contact">
         <a href="https://wa.me/+905322206524"> <BsWhatsapp className="contact-icon"/></a>
         <a href="mailto:info@digilinko.com"> <EmailOutlinedIcon className="contact-icon"/></a>

        </div>
      </div>

    </div>
  );
};

export default Login;
