import "./HomeNavbar.scss";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { useEffect } from "react";

import React, { useState } from "react";
import { Link } from "react-router-dom"; // Sayfalar arası geçişler için
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
const Navbar = () => {
  const navitage = useNavigate();
  const { t, i18n  } = useTranslation();

  const [isOpen, setIsOpen] = useState(false); // Hamburger menü durumunu tutacak state

  // Hamburger menüyü aç/kapat
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };



  function navigateToUpdatePassword(){
   window.location.assign("/updatepassword");
 }
 function navigateToUpdateUser(){
  window.location.assign("/updateuser");
}
  
  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="Homenavbar">
        <nav>
      <div className="logo">
        <Link to="/updateuser"><img src="/images/Digilinko-logo-beyaz-yatay.png"/></Link>
      </div>
      <ul className={`menu ${isOpen ? "open" : ""}`}>

        <div className="items">
        <div className="item">
        <div className="language-select-div">
        <select id="language-select" onChange={handleLanguageChange}>
          <option value="en">EN</option>
          <option value="de">DE</option>
          <option value="tr">TR</option>
          <option value="ru">RU </option>
        </select>
      </div>
        </div>
        <div className="item">
            <button onClick={navigateToUpdateUser} className="">
            {t('navbar.updatePage')}
            </button>
          </div>
        <div className="item">
            <button onClick={navigateToUpdatePassword} className="">
            {t('navbar.passwordPage')}
            </button>
          </div>


        </div>
      </ul>
      <div className="menu-icon" onClick={toggleMenu}>
        <i className={`fas fa-${isOpen ? "times" : "bars"}`}><MenuOutlinedIcon/></i>
      </div>
    </nav>
      <div className="wrapper">


      </div>
    </div>



  );
};

export default Navbar;
