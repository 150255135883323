import React, { useContext, useEffect } from "react";
import "./Intro.scss";

import { Link } from "react-scroll";
const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };





  return (
    <div className="Intro" id="Intro">
      <div className="container"> 
        <div className="border-container">
            <h1>NFC KARTVIZITLER ILE ORMANLARIMIZI KURTARALIM</h1>
        </div>
      </div>


    </div>
  );
};

export default Intro;
