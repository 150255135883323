import React, { useContext, useEffect } from "react";
import "./Midcontainer.scss";


const Midcontainer = () => {
    // Transition
    const transition = { duration: 2, type: "spring" };
  
  
  
  
  
    return (
      <div className="Midcontainer" id="">
        <div className="right-mobile"> 

            <img src="/images/digilinko-mockup.png" />

        </div>
        <div className="left"> 
          <div className="content-container">
              <h1>İletişimdeki gecikmeleri ortadan kaldırın</h1>
              <p>Digilinko, NFC kartvizit  ile iş hayatınızı daha da kolaylaştırıyor. Tanıştığınız kişinin telefonuna yaklaştırdığınız Digilinko kartvizitinizi okutarak, rehbere fotoğrafınızla birlikte şirketinizin adını, mail adresinizi, konumunuzu ve paylaşmak istediğiniz diğer bilgilerinizi ekleyebilirsiniz. Bir kere belirlediğiniz bu bilgileri, dilediğiniz kişiye saniyeler içinde aktarabilirsiniz. Hem çevre dostu hem de daha profesyonel bir yöntem olan Digilinko kartvizit uygulaması ile kartvizitlerinizi dijital hale dönüştürün ve iş hayatınıza yeni bir boyut katın.</p>
              <button>İletişime Geç</button>

          </div>
        </div>
        <div className="right"> 

            <img src="/images/digilinko-mockup.png" />

        </div>
  
  
      </div>
    );
  };
  
  export default Midcontainer;